@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
html, body {
  background-color: #191919;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

a {
  color: #0366d6;
}

strong {
  color: var(--bs-primary);
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

header {
  position: fixed;
  width: 100%;
}

.spacer {
  height: 56px;
  width: 100%;
}

::-webkit-scrollbar {
  width: 15px;
  border-left: 1px solid #333;
}
::-webkit-scrollbar:horizontal {
  height: 10px;
  border-left: 0;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #2c2c2c;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--bs-primary);
}
::-webkit-scrollbar-thumb:horizontal {
  background-color: #2c2c2c;
  border-radius: 0;
  border: 0;
}

::-webkit-scrollbar-track {
  background-color: #191919;
  border-left: 1px solid #2c2c2c;
}
::-webkit-scrollbar-track:horizontal {
  background-color: transparent;
  border-left: 0;
}
