.product {
  display: flex;
  flex-direction: column;
}
.product h2 {
  text-decoration: underline;
  text-decoration-color: var(--bs-primary);
}
.product:not(:first-child) {
  margin-top: 60px;
}
.product .carousel {
  width: 100%;
}
.product .carousel-item {
  height: 575px;
}
.product .carousel-item img {
  width: 100%;
}
.product .carousel-caption h3, .product .carousel-caption p {
  display: none;
}
.product .desc {
  margin-top: 20px;
}
