.partners {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 820px;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 20%;
}
.partners .holderRight {
  width: 40%;
  text-align: right;
}
.partners .holderRight h1 {
  text-decoration: underline;
  text-decoration-color: var(--bs-primary);
}
.partners .holderLeft {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  border-left: 1px solid var(--bs-primary);
  margin-left: 20px;
  padding-left: 20px;
  text-align: center;
}
.partners .holderLeft p {
  font-size: 20px;
  font-weight: bold;
  color: #CBCCCD;
  padding: 3px;
  background-color: #2c2c2c;
  border-radius: 8px;
  cursor: pointer;
  margin: 3px;
}
.partners .holderLeft p:hover {
  color: var(--bs-primary);
}

@media only screen and (max-width: 600px) {
  .partners {
    flex-direction: column;
    width: 100%;
  }
  .partners .holderRight {
    width: 85%;
    text-align: left;
  }
  .partners .holderLeft {
    width: 90%;
    border-top: 1px solid var(--bs-primary);
    border-left: 0;
    padding-top: 20px;
  }
}
