@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
header {
  z-index: 2;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
  font-family: "Lobster", sans-serif;
  font-size: 24px;
  padding: 0;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}
.nav-item:hover {
  color: var(--bs-primary);
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}
