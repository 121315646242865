.contacts {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 820px;
  height: 100%;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 20%;
}
.contacts h1 {
  text-decoration: underline;
  text-decoration-color: var(--bs-primary);
}

@media only screen and (max-width: 600px) {
  .contacts {
    width: 85%;
  }
}
