@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
.home .company-name {
  font-family: "Lobster", sans-serif;
}
.home section {
  display: flex;
  flex-direction: row;
  margin: auto;
  height: fit-content;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.home section svg {
  float: right;
  height: 500px;
}
.home section h1 {
  width: 400px;
}
.home section .typed-cursor {
  color: rgb(13, 110, 253);
}
.home .contentStyle1 {
  background-color: #2c2c2c;
}
.home .contentStyle1 .holder svg {
  margin-right: 30px;
}
.home .contentStyle1 .holder .holder1 {
  text-align: left;
}
.home .contentStyle2 svg {
  margin-left: 30px;
}
.home .contentStyle2 .holder .holder1 {
  text-align: right;
}
.home .content {
  margin-top: 100px;
  /*animation: fade-right 1s ease-in;*/
  padding: 20px;
  height: 500px;
}
.home .content .holder {
  display: flex;
  flex-direction: row;
  width: 800px;
  align-items: center;
  justify-content: center;
}
.home .content .holder svg {
  width: 400px;
  height: 400px;
}
.home .content .holder .holder1 {
  display: flex;
  flex-direction: column;
  width: calc(100% - 430px);
}
.home .content .holder .holder1 h2 {
  white-space: nowrap;
  text-decoration: underline;
  text-decoration-color: var(--bs-primary);
}
.home .content .holder .holder1 p {
  line-height: 24px;
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@media only screen and (max-width: 600px) {
  .content svg {
    display: none;
  }
  .content .holder .holder1 {
    width: 100% !important;
  }
}
