.products {
  margin: auto;
  margin-top: 30px;
  width: 800px;
}

@media only screen and (max-width: 600px) {
  .products {
    width: 85%;
  }
}
