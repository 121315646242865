.footer {
  background-color: #2c2c2c;
  margin-top: 100px;
  padding-top: 20px;
}
.footer img {
  width: 100%;
}
.footer h6 {
  color: var(--bs-primary);
}
.footer h1 {
  text-decoration: underline;
  text-decoration-color: var(--bs-primary);
}
.footer a {
  color: #fff;
}
